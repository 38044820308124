import React, { useState, useEffect, useRef } from "react";
import NoteContext from "./NoteContext";
import axiosInstance from "./../axios";
import { logoutSession } from "useable/supportFunctions";
import toast from "react-hot-toast";

const NoteState = (props) => {
  const [myaccoutstate, setMyAccountState] = useState({});
  const [vehicles, setVehicles] = useState([]);
  const [Coupon, setCoupons] = useState([]);
  const [isCouponLoaded, setIsCouponLoaded] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isNotificationLoaded, setIsNotificationLoaded] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [hasMore, setHasMore] = useState(true); // Pagination tracking

  const io = useRef(null); // WebSocket reference

  // Delete a vehicle
  const DeleteVehicle = async (id) => {
    try {
      await axiosInstance.delete(`/consumer_dash/my_vechicle_urd/${id}`);
      setVehicles((prevVehicles) =>
        prevVehicles.filter((vehicle) => vehicle.id !== id)
      );
      toast.success("Vehicle deleted successfully.");
    } catch (err) {
      toast.error("Failed to delete the vehicle.");
    }
  };

  // Fetch profile data
  const fetchProfileData = async () => {
    try {
      const response = await axiosInstance.get(
        "/consumer_dash/edit_consumer_profile"
      );
      setMyAccountState(response.data.response.user);
    } catch (err) {
      if (err.response?.status === 401) {
        logoutSession();
      } else {
        toast.error("Something went wrong.");
      }
    }
  };

  // Fetch coupon data
  const fetchCouponData = async () => {
    try {
      const response = await axiosInstance.get("/consumer_dash/user_coupan");
      setCoupons(response.data.response.data);
    } catch (err) {
      if (err.response?.status === 401) {
        logoutSession();
      } else {
        toast.error("Something went wrong.");
      }
    }
  };

  // Fetch all initial data
  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchProfileData();

        const vehiclesResponse = await axiosInstance.get(
          "/consumer_dash/my_vechicle"
        );
        setVehicles(vehiclesResponse.data.response);

        setIsCouponLoaded(false);
        await fetchCouponData();
      } catch (err) {
        if (err.response?.status === 401) {
          logoutSession();
        } else {
          toast.error("Something went wrong.");
        }
      } finally {
        setIsCouponLoaded(true);
      }
    };

    fetchData();
  }, []);

  // Load more notifications via WebSocket
  const loadMoreNotifications = (page) => {
    const token = sessionStorage.getItem("user__token") || "";
    if (io.current && io.current.readyState === WebSocket.OPEN) {
      io.current.send(JSON.stringify({ token, page }));
    }
  };

  // WebSocket setup and management
  useEffect(() => {
    if (!myaccoutstate.id) return;

    const token = sessionStorage.getItem("user__token") || "";
    const wsUrl = `${process.env.REACT_APP_BACKEND_DEVELOPMENT_URL_SOCKET}/ws/notification/${myaccoutstate.id}/?t=${token}`;

    if (!process.env.REACT_APP_BACKEND_DEVELOPMENT_URL_SOCKET) {
      console.error("WebSocket URL is not defined in the environment variables.");
      return;
    }

    io.current = new WebSocket(wsUrl);

    const handleOpen = () => {
      if (io.current.readyState === WebSocket.OPEN) {
        loadMoreNotifications(1); // Start with the first page
        setIsNotificationLoaded(false);
      }
    };

    const handleMessage = (e) => {
      try {
        const data = JSON.parse(e.data);

        // Append new notifications, avoiding duplicates
        // Append all new notifications to the existing ones
        setNotifications((prevNotifications) => [...prevNotifications, ...data.n]);
        setUnreadCount(data.notifications_unread);
        setHasMore(data.has_next);
      } catch (error) {
        toast.error("Failed to load notifications.");
      } finally {
        console.log(notifications);
        setIsNotificationLoaded(true);
      }
    };

    const handleError = () => {
      toast.error("WebSocket error occurred.");
    };

    io.current.onopen = handleOpen;
    io.current.onmessage = handleMessage;
    io.current.onerror = handleError;

    return () => {
      io.current.close();
    };
  }, [myaccoutstate]);

  return (
    <NoteContext.Provider
      value={{
        myaccoutstate,
        notifications: notifications.sort(
          (a, b) => new Date(b.date_created) - new Date(a.date_created)
        ),
        unreadCount,
        vehicles,
        DeleteVehicle,
        Coupon,
        isNotificationLoaded,
        isCouponLoaded,
        fetchProfileData,
        loadMoreNotifications,
        hasMore,
        fetchCouponData,
      }}
    >
      {props.children}
    </NoteContext.Provider>
  );
};

export default NoteState;
