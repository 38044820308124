import { Link } from "react-router-dom";
import axiosInstance from "./../../axios";
const emptyProfileIcon = "/assets/icons/Icon feather-user.svg";

const ProfilePopup = ({ isOpen = false, profile_pic, name, email }) => {
  async function Switchprovider() {
    await axiosInstance
      .post("consumer_dash/switch_pro")
      .then((response) => {
        window.location.replace(
          process.env.REACT_APP_PROVIDER_URL +
          "/login" 
        );
      })
      .catch((err) => {
        // console.log(err.response);
        //window.location.reload();
      });
  }

  return (
    <>
      {isOpen && (
        <div className="profilepopup">
          <Link to={"/profile"}>
            <div className="d-flex flex-row justify-content-between align-items-center mt-3">
              <div className="d-flex align-items-center justify-content-center">
                <img
                  className="profilepic"
                  src={profile_pic}
                  alt="profile"
                ></img>
              </div>
            </div>
          </Link>
          <p
            className="username mb-0 text-break text-center"
            style={{
              width: "315px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {name}
          </p>
          <p className="email mb-0">{email}</p>
          <Link
            to="#"
            role="button"
            data-bs-toggle="modal"
            data-bs-target="#inviteModal"
            className="invite mb-0"
          >
            Invite a friend
          </Link>
          <button className="switchbtn" onClick={Switchprovider}>
            Become a provider
          </button>

          <button
            data-bs-toggle="modal"
            data-bs-target="#logoutModal"
            className="logoutbtn"
          >
            Log out
          </button>

          <hr className=""></hr>

          <Link to="/terms-conditions" className="invite">
            Privacy Policy . Terms of Service
          </Link>
        </div>
      )}
    </>
  );
};

export default ProfilePopup;
